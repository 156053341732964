import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  useGetCryptoPortfolioQuery,
  useGetallUserQuery,
} from "../../redux/userApi";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import {
  useGetSelectedLeverageQuery,
  useGetUserForexPortfolioMutation,
} from "../../redux/forexApi";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../util/decimalFormatter";
import EconomicCalendar from "../pages/EchonomicalCalander";

const Dashboard = () => {
  const token = localStorage.getItem("jwtToken");
  const { t } = useTranslation();
  const { data } = useGetallUserQuery();
  const { data: cryptoPortfolio } = useGetCryptoPortfolioQuery({
    skip: !token,
  });
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const { data: selectedLeverages } = useGetSelectedLeverageQuery();
  // const [floatingProfits, setFloatingProfits] = useState(0.0);
  const [userLeverage, setUserLeverage] = useState("");
  const [usedBalance, setUsedBalance] = useState(0.0);
  const [totalBalance, setTotalBalance] = useState(0.0);
  const [freeBalance, setFreeBalance] = useState(0.0);
  const username = data?.user_name || "N/A";

  useEffect(() => {
    if (token) {
      // getForexPortfolio();
      getEquityPortfolio();
    }
  }, []);

  const getForexPortfolio = async () => {
    const data = {
      symbolType: "forex",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      // setFloatingProfits(resp.data);
    }
  };

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFreeBalance(resp.data.freeBalance ? resp.data.freeBalance : "0.0");
      setUsedBalance(resp.data.usedMargin ? resp.data.usedMargin : "0.0");
      setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
    }
  };

  useEffect(() => {
    if (selectedLeverages) {
      setUserLeverage(selectedLeverages.amount);
    } else {
      setUserLeverage(1);
    }
  }, [cryptoPortfolio, selectedLeverages]);

  return (
    <div>
      <Navbar />
      <section className="p70  pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3    bg2 br20 mb-4">
                <h5 className="mb-2">{t("For You")}</h5>
                <div className=" row ">
                  <div className="col-md-8">
                    <div className=" row  dashbord">
                      <div className="col-4">
                        <Link to="/crypto/BTC/USDT" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/crypto.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("Crypto")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                      <div className="col-4">
                        <Link to="/forex-equity/GOOGL/" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/equity.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("Equity")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                      <div className="col-4 ">
                        <Link to="/forex/EURUSD" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/fx.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("FX")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3   bg2 br20 mb-4">
                <div className="row">
                  <div className="col-md-3">
                    {" "}
                    <h5 className="mb-3">
                      {t("User ID")}:<span className="ml-3">{username}</span>
                    </h5>
                  </div>
                  <div className="col-md-3">
                    {" "}
                    <h5 className="mb-3">
                      {t("Leverage")}:
                      <span className="ml-3">
                        1:{userLeverage ? userLeverage : "1"}
                      </span>
                    </h5>
                  </div>
                </div>

                <hr />
                <h5 className="mb-3">{t("Portfolio")}</h5>
                <div className="row current_unds_item">
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("Total")} {t("Balance")}
                    </p>
                    <h4>
                      
                      $
                      {isNaN(totalBalance)
                        ? "0.00"
                        : formatDecimal(totalBalance, 2)}
                    </h4>
                  </div>
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("Used")} {t("Balance")}
                    </p>
                    <h4>
                      $
                      {isNaN(usedBalance)
                        ? "0.00"
                        : formatDecimal(usedBalance, 2)}
                    </h4>
                  </div>
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("Free")} {t("Balance")}
                    </p>
                    <h4>
                      $
                      {isNaN(freeBalance)
                        ? "0.00"
                        : formatDecimal(freeBalance, 2)}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="p-3   bg2 br20">{EconomicCalendar()}</div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard;
